<template>
  <div>
    <div v-if="isDash" class="mb-24 sub-heading-md-text">
      {{ $lang('myPagesResources', 'autoshipHeading') }}
    </div>
    <div v-if="loading" class="pb-40">
      <div class="w-full text-center">
        <div
          class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-32 w-32 inline-block mb-24 max-w-1/2 mx-auto"
        />
        <div class="wysiwyg wysiwyg--sm block md:text-center">
          {{ $lang('myPagesResources', 'fetchingSubscriptions') }}
        </div>
      </div>
    </div>
    <div v-else-if="subscriptions && subscriptions.length <= 0" class="pb-40">
      {{ $lang('myPagesResources', 'noSubscriptionsFound') }}
    </div>
    <div v-else class="pb-40">
      <div
        v-for="subscription in subscriptions"
        :key="subscription.subscriptionId"
        class="border border-grey600 p-24 mb-40 last:mb-0 flex"
      >
        <div
          v-if="!isMobile"
          class="order-section mr-24 max-w-380 flex flex-wrap content-start"
        >
          <div
            v-for="imageItem in subscription.items"
            :key="'imageItem-' + imageItem.partNo"
            class="bg-grey400"
            :class="{
              'h-full w-full p-56': subscription.items.length === 1,
              'small-image-item': subscription.items.length > 1,
            }"
          >
            <nuxt-img
              preset="productImage"
              loading="lazy"
              :src="imageItem.imageUrl"
              class="object-contain h-full w-full"
              :alt="imageItem.productName"
            />
          </div>
        </div>
        <div class="order-section">
          <div
            v-if="subscription.errorMessage"
            class="p-12 bg-error rounded text-white mb-12"
          >
            <fa class="mr-2" :icon="['fas', 'exclamation-circle']" />
            {{ subscription.errorMessage }}
          </div>
          <div v-if="subscription.nextDelivery" class="p-12 bg-grey400 rounded">
            <fa :icon="['fal', 'truck']" />
            {{ $lang('myPagesResources', 'nextDelivery') }}:
            {{ dayjs(subscription.nextDelivery).locale(config.language).fromNow() }}
          </div>
          <div
            v-else-if="subscription.holdUntilDate && !subscriptionIsPaused(subscription)"
            class="p-12 bg-grey400 rounded"
          >
            <fa :icon="['fal', 'truck']" />
            {{ $lang('myPagesResources', 'firstOrderDate') }}:
            {{ getFormattedDate(subscription.holdUntilDate) }}
          </div>
          <div
            v-if="subscriptionIsPaused(subscription)"
            class="p-12 bg-grey400 rounded"
          >
            {{ $lang('myPagesResources', 'subscriptionPaused') }}
          </div>
          <div v-if="isMobile" class="border-b border-grey600 pt-24 pb-12">
            <div class="flex flex-wrap content-start">
              <div
                v-for="imageItem in subscription.items"
                :key="'smallImageItem-' + imageItem.partNo"
                class="bg-grey400 sm:h-128 sm:w-128 h-64 w-64 mr-12 mb-12 p-12"
              >
                <nuxt-img
                  preset="productImage"
                  loading="lazy"
                  :src="imageItem.imageUrl"
                  class="object-contain h-full w-full"
                  :alt="imageItem.productName"
                />
              </div>
            </div>
          </div>
          <div v-if="!subscription.edit">
            <div class="border-b border-grey600 py-24">
              <div class="flex flex-1 justify-between mb-12">
                <div class="small-base-text w-3/5">
                  {{ $lang('myPagesResources', 'productsHeading') }}
                </div>
                <div class="small-base-text w-1/5">
                  {{ $lang('myPagesResources', 'quantityHeading') }}
                </div>
                <div class="small-base-text w-1/5 flex justify-end">
                  {{ $lang('myPagesResources', 'sumHeading') }}
                </div>
              </div>
              <div
                v-for="item in subscription.items"
                :key="'subscriptionItem' + item.partNo"
                class="flex flex-1 justify-between mb-12 last:mb-0"
              >
                <div class="small-base-regular-text w-3/5 pr-16">
                  {{ item.productName }}
                </div>
                <div class="small-base-regular-text w-1/5">
                  {{ item.quantity }}
                </div>
                <div class="small-base-regular-text w-1/5 flex justify-end">
                  {{ item.totalSum }}
                </div>
              </div>
            </div>
            <div class="border-b border-grey600 py-24">
              <div class="flex flex-1 justify-between mb-8">
                <div class="small-base-regular-text">
                  {{ $lang('myPagesResources', 'deliveryFrequencyHeading') }}
                </div>
                <div class="small-base-text">
                  {{ selectedDeliveryFrequencyName(subscription) }}
                </div>
              </div>
              <div class="flex flex-1 justify-between mb-8">
                <div class="small-base-regular-text">
                  {{ $lang('myPagesResources', 'paymentMethodHeading') }}
                </div>
                <div class="small-base-text">
                  {{
                    $lang(
                      'myPagesResources',
                      'paymentMethodId' + subscription.paymentMethod
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="border-b border-grey600 py-24">
              <div class="flex flex-1 justify-between mb-8">
                <div class="small-base-regular-text">
                  {{ subscription.selectedDeliveryMethodName }}
                </div>
                <div class="small-base-text">
                  {{ subscription.shipping }}
                </div>
              </div>
              <div class="flex flex-1 justify-between mb-8">
                <div class="preamble-text">
                  {{ $lang('myPagesResources', 'totalSumHLabel') }}
                </div>
                <div class="preamble-text">
                  {{ subscription.totalAmount }}
                </div>
              </div>
              <div class="flex flex-1 justify-between mb-8">
                <div class="small-base-regular-text text-grey600">
                  {{ $lang('myPagesResources', 'vatLabel') }}
                </div>
                <div class="small-base-regular-text text-grey600">
                  ({{ subscription.vat }})
                </div>
              </div>
            </div>
            <div class="mt-24">
              <Button
                :text="$lang('myPagesResources', 'autoshipEditAction')"
                :size="Sizes.lg"
                :theme="Themes.dark"
                :is-block="true"
                @click="editSubscription(subscription)"
              />
            </div>
          </div>
          <div v-else>
            <div
              v-for="(item, idx) in subscription.editedSubscription?.items"
              :key="item.partNo"
              class="py-16 border-b border-grey600 relative"
            >
              <div class="flex">
                <div class="h-40 w-40 mr-12">
                  <nuxt-img
                    preset="productImage"
                    loading="lazy"
                    :src="item.imageUrl"
                    height="40"
                    width="40"
                    class="max-h-40 max-w-40 object-contain"
                    :alt="item.productName"
                  />
                </div>
                <div class="flex-1">
                  <div class="flex justify-between">
                    <div class="meta-info-text">
                      {{ item.productName }}
                    </div>
                    <div class="meta-info-text flex-shrink-0 ml-16 text-right">
                      {{ item.priceDisplayWithoutCurrency }} {{ subscription.priceSuffix }}
                    </div>
                  </div>
                  <div class="absolute right-0 mt-32 top-0" />
                  <div class="flex justify-between items-center mt-12">
                    <div class="flex">
                      <div class="flex min-w-88 items-center border border-grey600 bg-white rounded justify-between small-base-text">
                        <div
                          class="pl-12 pr-16 py-4 cursor-pointer select-none small-base-text"
                          @click="decreaseQuantity(item, subscription, idx)"
                        >
                          &mdash;
                        </div>
                        {{ item.quantity }}
                        <div
                          class="pr-12 pl-16 py-4 cursor-pointer select-none small-base-text"
                          @click="increaseQuantity(item, subscription, idx)"
                        >
                          +
                        </div>
                      </div>
                    </div>
                    <div
                      class="cursor-pointer select-none"
                      @click="deleteItem(item, subscription, idx)"
                    >
                      <img
                        src="~/assets/icons/close.png"
                        class="h-16 w-16"
                        height="16"
                        width="16"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="item in subscription.popularProducts"
              :key="'populatProducts' + item.partNo"
              class="py-16 border-b border-grey600 relative"
            >
              <div class="flex">
                <div class="h-40 w-40 mr-12">
                  <nuxt-img
                    preset="productImage"
                    loading="lazy"
                    :src="item.imageUrl"
                    height="40"
                    width="40"
                    class="max-h-40 max-w-40 object-contain"
                    :alt="item.productName"
                  />
                </div>
                <div class="flex-1">
                  <div class="flex justify-between">
                    <div class="meta-info-text">
                      <div>{{ item.productName }}</div>
                      <div v-if="item.packageSize > 1">{{ item.packageSize }}-pack</div>
                      <Button
                        class="text-center mt-2"
                        :text="$lang('myPagesResources', 'addToSubscription')"
                        :size="Sizes.xs"
                        :theme="Themes.ghost"
                        @click="addToSubscription(item, subscription)"
                      />
                    </div>
                    <div class="meta-info-text flex-shrink-0 ml-16 text-right">
                      {{ item.priceDisplayWithoutCurrency }} {{ subscription.priceSuffix }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="small-base-text mb-8 mt-16">
              {{ $lang('checkoutResources', 'autoshipHeading') }}
            </div>
            <div class="border rounded border-grey600 overflow-hidden">
              <Selector
                small
                :items="subscriptionSelectableIntervals(subscription)"
                @changed="
                  (value)=>
                    setSubscriptionInterval(value, subscription)
                "
              />
            </div>
            <div
              v-if="subscriptionIsPaused(subscription)"
              class="mb-16"
            >
              <div class="small-base-text mb-8 mt-16">
                {{ $lang('myPagesResources', 'holdUntilDateTitle') }}
              </div>
              <VueDatePicker
                v-model="subscription.editedSubscription.holdUntilDate"
                :format="format"
                :preview-format="format"
                :min-date="today"
                :locale="config.currentMarket?.cultureCode"
                :select-text="$lang('myPagesResources', 'select')"
                class="date-picker-subscriptions w-full text-small-medium h-40 text-small-regular mb-16"
              />
            </div>
            <div class="small-base-text mb-8 mt-24">
              {{ $lang('checkoutResources', 'deliveryUpdateHeading') }}
            </div>

            <IngridForm
              v-if="ingridSnippet"
              :snippet="ingridSnippet"
              :purchase-type="purchaseType"
              :subscription-id="subscription.subscriptionId"
            />
            <div v-else class="flex items-center justify-center mt-24">
              <div class="w-full text-center">
                <div
                  class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-32 w-32 inline-block mb-24 max-w-1/2 mx-auto"
                />
                <div class="wysiwyg wysiwyg--sm block md:text-center">
                  {{ $lang('myPagesResources', 'loading') }}
                </div>
              </div>
            </div>
            <UpdateDeliveryAddress
              ref="updateDeliveryAddress"
              :subscription="subscription"
              :is-mobile="isMobile"
              @submit-delivery-address="submitUpdateDeliveryAddress"
              @open-address-update="updateAddressOpened = true"
            />
            <div v-if="updateAddressOpened" class="w-full mt-16">
              <Checkbox
                class="text-xs"
                :checked="useDeliveryAddressAsInvoiceAddress === true"
                :text="
                  $lang(
                    'myPagesResources',
                    'useDeliveryAddressAsInvoiceAddress'
                  )
                "
                @on-check="useDeliveryAddressAsInvoiceAddress = true"
              />
            </div>

            <div
              v-if="!subscriptionIsPaused(subscription)"
              class="py-16 border-b border-grey600 flex flex-wrap items-center justify-center"
            >
              <div class="w-full md:w-1/2 mb-12 md:mb-0 md:pr-8">
                <Button
                  class="text-center"
                  :text="$lang('sharedResources', 'cancel')"
                  :size="Sizes.sm"
                  :theme="Themes.ghost"
                  :is-block="true"
                  @click="cancelEdit(subscription)"
                />
              </div>
              <div class="w-full md:w-1/2 md:pl-8">
                <Button
                  class="text-center"
                  :text="$lang('sharedResources', 'save')"
                  :size="Sizes.sm"
                  :theme="Themes.dark"
                  :is-block="true"
                  :loading="loading"
                  @click="updateSubscription(subscription)"
                />
              </div>
            </div>
            <div v-if="subscriptionIsPaused(subscription)" class="mt-16">
              <div class="small-base-text mb-8">{{ $lang('myPagesResources', 'overview') }}</div> 
              <div class="bg-grey400 p-12 rounded-md sm:p-16"> 
                <div class="flex mb-4"> 
                  <div class="w-[55%] font-medium">
                    <fa
                      :icon="['far', 'truck']"
                      class="inline-block h-16 w-16 mr-6 align-sub" 
                      height="16" 
                      width="16"
                    />
                    {{ $lang('myPagesResources', 'firstOrderDate') }}:
                  </div> 
                  <div 
                    v-if="subscription.editedSubscription.holdUntilDate" 
                    class="w-[45%] pl-8"
                  > 
                    {{ getFormattedDate(subscription.editedSubscription.holdUntilDate) }}
                  </div> 
                </div> 
                <div class="flex mb-4"> 
                  <div class="w-[55%] font-medium"> 
                    <fa
                      :icon="['far', 'arrow-turn-down-right']"
                      class="inline-block h-16 w-16 mr-6 align-sub" 
                      height="16" 
                      width="16"
                    />
                    {{ $lang('myPagesResources', 'deliveryInterval') }}:
                  </div> 
                  <div 
                    class="w-[45%] pl-8"
                  >
                    {{ selectedSubscriptionInterval(subscription)[0].name }} 
                  </div> 
                </div>
                <div class="flex"> 
                  <div class="w-[55%] font-medium">
                    <fa
                      :icon="['far', 'shopping-bag']"
                      class="inline-block h-16 w-16 mr-6 align-sub" 
                      height="16" 
                      width="16"
                    />
                    {{ $lang('myPagesResources', 'total') }}:
                  </div> 
                  <div 
                    class="w-[45%] pl-8" 
                  >
                    {{ Math.round(totalEditedPrice(subscription)) }} {{ subscription.priceSuffix }}
                  </div> 
                </div> 
              </div> 
            </div>
            <div class="py-16 border-b border-grey600 flex flex-wrap items-center justify-center">
              <a
                v-if="!subscriptionIsPaused(subscription)"
                class="underline hover:no-underline"
                href="#"
                @click.prevent="pauseSubscription(subscription)"
              >
                {{ $lang('myPagesResources', 'pauseSubscription') }}
              </a>
              <button
                v-else
                class="underline"
                @click.prevent="resumeSubscription(subscription)"
              >
                {{ $lang('myPagesResources', 'resumeSubscription') }}
              </button>
            </div>
            <div class="py-16 border-b border-grey600 flex flex-wrap items-center justify-center">
              <div
                class="text-error underline cursor-pointer hover:no-underline"
                @click.prevent="showDeleteSubscriptionModal = true, activeSubscription = subscription"
              >
                {{ $lang('myPagesResources', 'cancelSubscription') }}
              </div>
            </div>
            <AlertBar
              v-if="updateError.length > 0"
              class="my-8"
              :text="updateError"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isDash" class="mypage-faq-blocks">
      <BlocksContentArea
        v-for="(block, index) in faqBlocks"
        :key="'MyPageFaqBlocks-' + block?.ContentLink?.Expanded?.__typename + '-' + index"
        :block="block?.ContentLink?.Expanded"
      />
    </div>
    <div class="md:flex md:items-center md:justify-center mb-40">
      <p class="meta-info-regular-text mr-12 mb-12 md:mb-0 text-center">
        {{ $lang('myPagesResources', 'subscriptionContact') }}
      </p>
      <nuxt-link
        v-if="contactPageUrl"
        :to="contactPageUrl"
        class="text-center btn btn--sm small-base-text btn--dark-ghost-theme btn--wide"
      >
        {{ $lang('myPagesResources', 'subscriptionContactButton') }}
      </nuxt-link>
    </div>
    <Modal
      v-if="showDeleteSubscriptionModal"
      class=""
      @close-modal="onCloseCancelSubscriptionModal"
    >
      <div class="p-24">
        <h5 class="mb-16">{{ $lang('myPagesResources', 'cancelSubscriptionModalTitle') }}</h5>
        <Button
          class="mb-12"
          :is-block="true"
          :text="$lang('myPagesResources', 'cancelSubscription')"
          :size="Sizes.lg"
          :theme="Themes.ghost"
          :loading="loadingCancelSubscription"
          @click="cancelSubscription"
        />
        <Button
          :is-block="true"
          :text="$lang('sharedResources', 'close')"
          :size="Sizes.lg"
          :theme="Themes.dark"
          @click="onCloseCancelSubscriptionModal"
        />
      </div>
    </Modal>
  </div>
</template>
<script setup lang="ts">
import type { MyPageFragment } from '#gql';
import { useGlobalContentStore } from '~/store/globalContent';
import Checkbox from '~/components/form-elements/Checkbox.vue';
import AlertBar from '~/components/globals/AlertBar.vue';
import Button from '~/components/globals/Button.vue';
import { Themes, Sizes } from '../../constants/button-layouts';
import _clone from 'lodash/clone';
import _omit from 'lodash/omit';
import _cloneDeep from 'lodash/cloneDeep';
import Selector from '../form-elements/Selector.vue';
import UpdateDeliveryAddress from './UpdateDeliveryAddress.vue';
import {
  type ISubscriptionOverview,
  type ISubscriptionOverviewItem,
  SubscriptionStatus,
  PurchaseType
} from '~/models/api-types';
import { computed, onMounted, ref } from 'vue';
import Modal from '~/components/modals/Modal.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const config = useGlobalContentStore().config;
const { $lang } = useNuxtApp();
const { apiGet, apiPut, apiDelete, lastError } = useApiFetch();
const dayjs = useDayjs();
const activeSubscription = ref();
const today = ref(new Date());

type IAddress = {
  postalCode: string;
  city: string;
  line1: string;
  line2: string;
  careOf: string;
}

type IUser = {
  firstName: string;
	lastName: string;
	email: string;
	phone: string;
	newsletter: boolean;
	invoiceAddress: IAddress;
	deliveryAddress: IAddress;
	useInvoiceAddressAsDeliveryAddress: boolean;
}

interface EditableSubscription extends ISubscriptionOverview {
  editedSubscription: ISubscriptionOverview;
  edit: boolean;
}

const props = defineProps<{
  page: MyPageFragment,
  isMobile: boolean,
  isDash?: boolean,
  subscriptions?: EditableSubscription[],
}>();

const loading = ref(false);
const useDeliveryAddressAsInvoiceAddress = ref(false);
const updateAddressOpened = ref(false);
const updateError = ref('');
const user: Ref<IUser | undefined> = ref();
const availabilityToken = ref('');
const ingridSnippet = ref('');
const showDeleteSubscriptionModal = ref(false);
const loadingCancelSubscription = ref();
const subscriptions = ref();

subscriptions.value = props.subscriptions;

const purchaseType = PurchaseType.Subscription;

const faqBlocks = computed(() => {
  return (
    props.page.SubscriptionsFaqContentArea?.filter(
      (block) => block?.ContentLink?.Expanded !== null
    ) || []
  );
});

const contactPageUrl = computed(() => {
  return config.currentMarket?.contactPageUrl;
});

const format = (dateString: Date) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString(config.currentMarket?.cultureCode, {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  });

  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

const getFormattedDate = (date: Date) => {
  if (isToday(date)) {
    return 'Idag, ' + format(date).toLocaleLowerCase();
  } else {
    const formatted = format(date);
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }
};

const isToday = (date: Date) => {
  if (date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const givenDate = new Date(date);
    givenDate.setHours(0, 0, 0, 0);

    return today.getTime() === givenDate.getTime();
  }
  return false;
};

const onCloseCancelSubscriptionModal = () => {
  showDeleteSubscriptionModal.value = false;
  document.body.classList.remove('locked');
};

const editSubscription = async(subscription: EditableSubscription) => {
  subscription.edit = true;
  subscription.editedSubscription.holdUntilDate = today.value;
  await fetchIngridSnippet(subscription);
};

const fetchIngridSnippet = async(subscription: EditableSubscription) => {
  const query = `ingrid/snippet/?purchaseType=${purchaseType}&zip=${subscription.deliveryAddress.postalCode}&subscriptionId=${subscription.subscriptionId}`;
  const res = await apiGet(query);
  ingridSnippet.value = res.html_snippet;

  let interval: any;
  let count = 0;

  // Wait for ingrid script to load
  interval = setInterval(() => {
    count++;
    if (count < 16) {
      if (window._sw) {
        window._sw(async(api) => {
          api.on('loaded', function() {
          });
        });
        setTimeout(() => { // Fallback if loaded event is missed.
        }, 500);
        clearInterval(interval);
      }
    }
  }, 100);
};

const subscriptionIsPaused = (subscription: EditableSubscription) => {
  const statusEnum = SubscriptionStatus[subscription.status];
  return statusEnum.toString() === SubscriptionStatus.Paused.toString();
};

const cancelEdit = (subscription: EditableSubscription) => {
  subscription.editedSubscription = _cloneDeep(
    _omit(subscription, 'editedSubscription')
  );
  subscription.edit = false;
};

const subscriptionSelectableIntervals = (subscription: EditableSubscription) => {
  return subscription.editedSubscription?.scheduleIntervals.map((i) => {
    return {
      ...i,
      label: i.name,
    };
  });
};

const setSubscriptionInterval = (value: any, subscription: EditableSubscription) => {
  subscription.editedSubscription.scheduleIntervals = subscription.editedSubscription.scheduleIntervals.map((i) => {
    return {
      ...i,
      isSelected: i.value.toString() === value.toString(),
    };
  });
};

const selectedSubscriptionInterval = (subscription: EditableSubscription) => {
  return subscription.editedSubscription.scheduleIntervals.filter((i) => i.isSelected);
};

const selectedDeliveryMethod = (subscription: EditableSubscription) => {
  return subscription.editedSubscription.deliveryMethods.find(
    (d) => d.isSelected
  );
};

const selectedDeliveryFrequency = (subscription: EditableSubscription) => {
  return subscription.editedSubscription?.scheduleIntervals.find(
    (d) => d.isSelected
  );
};

const selectedDeliveryFrequencyName = (subscription: EditableSubscription) => {
  return selectedDeliveryFrequency(subscription)?.name ?? '';
};

const getDeliveryAddress = async(id: number) => {
  const { currentMarket, language } = config;
  const query = `user/subscriptions/${id}/deliveryaddress/?subscriptionId=${id}&${language}&countryCode=${currentMarket?.countryCode}`;
  const res = await apiGet(query);
  return res;
};

const setDeliveryAddress = async() => {
  for (let i = 0; subscriptions.value.length > i; i++) {
    const subscription = subscriptions.value[i] as EditableSubscription;
    await getDeliveryAddress(subscription.subscriptionId).then((x) => {
      subscription.deliveryAddress = x;
    });
  }
};

const submitUpdateDeliveryAddress = (value: {
  address: IAddress;
  subscription: EditableSubscription;
}) => {
  if (value.subscription) {
    value.subscription.editedSubscription.deliveryAddress = value.address;
  }
};

const getUpdateDeliveryAddress = (subscription: EditableSubscription) => {
  if (!subscription.editedSubscription.deliveryAddress) {
    subscription.editedSubscription.deliveryAddress =
      subscription.deliveryAddress;
  }
  return subscription.editedSubscription.deliveryAddress;
};

const increaseQuantity = (
  item: ISubscriptionOverviewItem,
  subscription: EditableSubscription,
  _idx: number
) => {
  item.quantity++;
  item.totalSum = buildItemPrice(item, subscription);
};

const decreaseQuantity = (
  item: ISubscriptionOverviewItem,
  subscription: EditableSubscription,
  idx: number
) => {
  if (item.quantity <= 1) {
    deleteItem(item, subscription, idx);
  } else {
    item.quantity--;
    item.totalSum = buildItemPrice(item, subscription);
  }
};

const buildItemPrice = (
  item: ISubscriptionOverviewItem,
  subscription: EditableSubscription
) => {
  const suffix = subscription.priceSuffix || '';
  const prefix = subscription.pricePrefix || '';
  return (
    prefix +
    (item.priceDisplayWithoutCurrency * item.quantity).toString() +
    ' ' +
    suffix
  );
};

const totalEditedPrice = (subscription: EditableSubscription) => {
  let updatedPrice = 0;

  subscription.editedSubscription.items.forEach((i) => {
    updatedPrice += (i.priceDisplayWithoutCurrency * i.quantity);
  });

  return updatedPrice;
};

const addToSubscription = (
  item: ISubscriptionOverviewItem,
  subscription: EditableSubscription
) => {
  const added = _clone(item);
  added.quantity = 1;
  added.totalSum = buildItemPrice(added, subscription);
  subscription.editedSubscription?.items.push(added);
  subscription.popularProducts = subscription.popularProducts.filter(
    (i) => i.partNo !== item.partNo
  );
};

const deleteItem = (
  _item: ISubscriptionOverviewItem,
  subscription: EditableSubscription,
  idx: number
) => {
  if (subscription.editedSubscription.items.length <= 1) {
    return;
  }
  subscription.editedSubscription.items.splice(idx, 1);
};

const pauseSubscription = async(subscription: EditableSubscription) => {
  await updateSubscription(subscription, SubscriptionStatus.Paused);
};

const resumeSubscription = async(subscription: EditableSubscription) => {
  await updateSubscription(subscription, SubscriptionStatus.Active);
};

const cancelSubscription = async() => {
  const subscription = activeSubscription.value;
  updateError.value = '';
  const { currentMarket, language } = config;
  loadingCancelSubscription.value = true;
  await apiDelete(`user/subscriptions/cancel?language=${language}&countryCode=${currentMarket?.countryCode}&id=${subscription.subscriptionId}`);
  await fetchSubscriptions();

  if (lastError.value) {
    updateError.value = $lang(
      'myPagesResources',
      'updateSubscriptionError'
    );
  }
  loadingCancelSubscription.value = false;

  window.scrollTo({ top: 0, behavior: 'smooth' });
  onCloseCancelSubscriptionModal();
};

const updateSubscription = async(
  subscription: EditableSubscription,
  status: SubscriptionStatus | null = null
) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  updateAddressOpened.value = false;
  updateError.value = '';
  const { currentMarket, language } = config;
  const overView = subscription.editedSubscription.items.filter(
    (i) => {
      return subscription.items.find((si) => si.partNo === i.partNo);
    }
  );

  const newProducts = subscription.editedSubscription.items.filter((i) => {
    return !subscription.items.find((si) => si.partNo === i.partNo);
  });

  const updated = {
    subscriptionId: subscription.subscriptionId, 
    subscriptionOverViewItemList: overView,
    newProducts: newProducts,
    deliveryMethodId: selectedDeliveryMethod(subscription)?.id,
    deliveryFrequency: selectedDeliveryFrequency(subscription)?.value,
    status: status || subscription.status,
    deliveryAddress: getUpdateDeliveryAddress(subscription),
    availabilityToken: availabilityToken.value,
    holdUntilDate: subscription.editedSubscription.holdUntilDate,
  };

  loading.value = true;
  await apiPut(
    `user/subscriptions/update?language=${language}&countryCode=${currentMarket?.countryCode}`,
    {
      SubscriptionId: updated.subscriptionId,
      SubscriptionOverViewItemList: updated.subscriptionOverViewItemList,
      NewProducts: updated.newProducts,
      DeliveryMethodId: updated.deliveryMethodId,
      DeliveryFrequency: updated.deliveryFrequency,
      Status: updated.status,
      DeliveryAddress: updated.deliveryAddress,
      AvailabilityToken: updated.availabilityToken,
      HoldUntilDate: subscription.editedSubscription.holdUntilDate,
    }
  );
  await fetchSubscriptions();

  if (lastError.value) {
    updateError.value = $lang(
      'myPagesResources',
      'updateSubscriptionError'
    );
  }

  if (useDeliveryAddressAsInvoiceAddress.value) {
    fetchUser(subscription);
  } else {
    loading.value = false;
  }
};

const fetchSubscriptions = async() => {
  const { currentMarket, language } = config;
  loading.value = true;
  
  const query = `user/subscriptions?language=${language}&countryCode=${currentMarket?.countryCode}`;
  const res = await apiGet(query);

  subscriptions.value = res?.map((r: ISubscriptionOverview) => {
    return {
      ...r,
      edit: false,
      editedSubscription: _cloneDeep(r),
      deliveryAddress: {},
    };
  });
  await setDeliveryAddress();

  loading.value = false;
};

// Fetch subscriptions if empty
if (!subscriptions.value) {
  fetchSubscriptions();
} else {
  setDeliveryAddress();
}

const fetchUser = async(subscription: EditableSubscription) => {
  const { currentMarket, language } = config;

  const query = `user/account?language=${language}&countryCode=${currentMarket?.countryCode}`;
  const res = await apiGet(query);
  
  user.value = res;
  setInvoiceAddress(subscription);
};

const setInvoiceAddress = (subscription: EditableSubscription) => {
  user.value!.invoiceAddress.line1 =
    subscription.editedSubscription.deliveryAddress.line1;
  user.value!.invoiceAddress.city =
    subscription.editedSubscription.deliveryAddress.city;
  user.value!.invoiceAddress.postalCode =
    subscription.editedSubscription.deliveryAddress.postalCode;
  user.value!.invoiceAddress.line2 =
    subscription.editedSubscription.deliveryAddress.line2;
  user.value!.invoiceAddress.careOf =
    subscription.editedSubscription.deliveryAddress.careOf;

  updateUser();
};

const updateUser = async() => {
  const { currentMarket } = config;
  const data = user.value;

  await apiPut(`user/account/update?language=${config.language}&countryCode=${currentMarket?.countryCode}`,
    {
      user: data,
    }
  );
  loading.value = false;
};
</script>
<style>
.order-section {
  @apply w-full;

  @screen md {
    width: calc(50% - 12px);
  }
}
.small-image-item {
  width: calc(50% - 5px);
  margin-top: 8px;
  max-height: 185px;
  @apply p-24;
}
.small-image-item:nth-child(2n) {
  margin-left: 8px;
}
.small-image-item:nth-child(1),
.small-image-item:nth-child(2) {
  margin-top: 0;
}

.date-picker-subscriptions {
  input {
    @apply text-xs font-medium leading-xs font-calibre;
  }
}

.dp__menu,
.dp__calendar_header_item,
.dp__action dp__select,
.dp__selection_preview {
  @apply font-calibre;
}

.dp__input_wrap::after {
  content: '';
  background-image: url('~/assets/icons/chevron-down-regular.svg');
  height: 18px;
  width: 14px;
  right: 14px;
  @apply pointer-events-none absolute bg-contain h-40 bg-no-repeat bg-center top-0;
}

.dp__input {
  @apply border-grey600;
}

.dp__input_icon {
  @apply text-black h-16 w-16;
}

.dp__action_cancel {
  @apply hidden;
}

.dp__action_select {
 @apply bg-success px-16 py-12 text-sm hover:bg-success;
}

.dp__clear_icon {
  @apply hidden;
}

.dp__input_readonly.dp__input.dp__input_icon_pad {
  @apply pr-28 h-40 uppercase;
}

.dp--tp-wrap {
  @apply hidden;
}

@media (max-width: 600px) {
  .dp__menu,
  .dp__outer_menu_wrap {
    @apply fixed !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}
</style>
<style scoped>
.loader {
  border-top-color: #fff;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
